.section-header{
  background-color: #291230;

  >img {
    width:100%;
  }
}

.home {
  
}